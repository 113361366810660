<template>
    <div class="mb-24">
        <loader
            class="z-50"
            :loading="loading"
            :backdrop="true"
        />
        <div class="w-full bg-white flex flex-col flex-1 flex fixed bottom-0 py-4 px-8">
            <h2 class="font-bold text-start my-4 text-base">
                Application status:
                <span class="text-purple-600">{{ application_status }}</span>
            </h2>
        </div>
        <div
            v-if="hasCredentials"
            id="bluemoon-application-container"
        ></div>
        <div
            v-else
            class="w-full text-center align-center mt-4"
        >
            <p>please set up the blue moon property to continue</p>
        </div>
    </div>
</template>

<script type="text/javascript">
import Loader from '@/components/ui/Loader';
import NotifyMixin from '@/mixins/NotifyMixin';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import { BM_CREDENTIALS_ERROR, UPDATE_APPLICATION_ERROR } from '@/utils/constants/leasing.js';
import { formatQuotedRentAmount } from '@/utils/Amount';

export default {
    components: { Loader },
    mixins: [NotifyMixin],
    data() {
        return {
            isCompleted: false,
            applicationCompleted: false,
            loading: false,
            token: '',
            id: '',
            hasCredentials: false,
            application_status: 'Application not Submitted',
            property: {
                id: '15430', // Fake property id
                name: '',
            },

            params: {
                view: 'esignature',
                skipStartPage: true,
                applicationId: '',
                applicationData: {},
            },

            document_instance_created: false,
        };
    },

    computed: {
        ...mapGetters({
            getDocumentInstance: 'application/getDocumentInstance',
            getApplication: 'application/getApplication',
            currentProfile: 'auth/currentProfile',
            getApplicationId: 'application/getApplicationId',
            getbluemoon_credentials: 'application/getbluemoon_credentials',
        }),

        getLeaseTerms() {
            const diff = moment(this.currentProfile.phaseEndDate).diff(moment(this.currentProfile.phaseStartDate), 'months', true);
            return `${Math.floor(diff)} months`;
        },
    },

    mounted() {
        this.fetchbluemoon_credentials();
    },

    methods: {
        ...mapActions({
            setDocumentInstance: 'application/setDocumentInstance',
            clearState: 'application/clearState',
            setApplicationId: 'application/setApplicationId',
            setbluemoon_credentials: 'application/setbluemoon_credentials',
        }),

        formatQuotedRentAmount,

        fetchbluemoon_credentials() {
            this.loading = true;
            this.$partnerDataProvider
                .getOne('community_blue_moon', {
                    customerUUID: this.currentProfile.customerId,
                    communityUUID: this.currentProfile.communityId,
                    purpose: 'AplicationLeasingGeneration',
                })
                .then(res => {
                    if (res.platformData) {
                        this.hasCredentials = true;
                        this.setbluemoon_credentials(res.platformData);
                        this.getAuthBlueMoonToken();
                    }
                })
                .catch(() => {
                    this.hasCredentials = false;
                    this.notifyError(BM_CREDENTIALS_ERROR);
                    this.loading = false;
                });
        },

        getAuthBlueMoonToken() {
            this.loading = true;
            this.$bmServiceDataProvider
                .create('authTokenBM', {
                    data: {
                        username: this.getbluemoon_credentials?.username,
                        password: this.getbluemoon_credentials?.password,
                        scope: 'full',
                        grant_type: 'password',
                        client_id: this.getbluemoon_credentials?.client_id,
                        client_secret: this.getbluemoon_credentials?.client_secret,
                        serial_number: this.getbluemoon_credentials?.serial_number,
                    },
                })
                .then(async res => {
                    if (this.$route.params.id) this.id = this.$route.params.id;
                    this.token = res.access_token;
                    await this.getProperty(this.getbluemoon_credentials?.serial_number, res.access_token);
                })
                .catch(() => {
                    this.notifyError('An authentication error occurred with Blue Moon');
                    this.loading = false;
                });
        },

        getProperty(account_id, token) {
            this.$bmServiceDataProvider
                .getList('property', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then(async res => {
                    this.property = res.data.find(property => property.account_id === account_id);
                    await this.leaseApplication();
                })
                .catch(() => {
                    this.notifyError('An error occurred getting the property from Blue Moon');
                    this.loading = false;
                });
        },

        leaseApplication() {
            // eslint-disable-next-line no-undef
            const bluemoonElements = new BluemoonElements(this.token, this.property.id);
            const applicationElement = bluemoonElements.load('bluemoon-application-container', 'bm-rental-application', {
                ...this.params,
                applicationId: this.getDocumentInstance.id,
                applicationData: this.getDocumentInstance ? { ...this.getDocumentInstance } : {},
            });

            applicationElement.addEventListener('onElementLoaded', e => {
                if (e.detail.elementId) this.loading = false;
            });

            applicationElement.addEventListener('onEsignatureComplete', async e => {
                const signed = e.detail.completed;
                const document_url = e.detail.document_url;
                if (signed) {
                    await this.updateApplicationRecord(
                        'Application submitted - Not paid',
                        "Application submitted but hasn't been paid yet",
                        'Application submitted (not paid)',
                        document_url
                    );
                }
            });
        },

        async updateApplicationRecord(
            process_status = 'In progress',
            event_description = 'The application has started',
            event_name = 'Application in progress',
            document_url = undefined
        ) {
            const new_application = { ...this.getApplication };
            delete new_application.application_id;
            delete new_application.occupancy_id;
            delete new_application.person_id;
            delete new_application.pets;
            const allPersons = [...new_application.persons];
            const currentApplicant = new_application.persons?.find(person => person.contact_id === this.currentProfile.contactId);
            const currentParty = new_application.parties?.find(party => party.contact_id === this.currentProfile.contactId);
            delete new_application.persons;

            try {
                await this.$leasingServiceDataProvider.patch('application', {
                    application_id: currentApplicant?.application[0]?.application.application_id ?? this.getApplicationId,
                    data: {
                        ...new_application,
                        occupancy_id: this.currentProfile.occupancyId,
                        first_name: this.getDocumentInstance.applicant_first_name,
                        last_name: this.getDocumentInstance.applicant_last_name,
                        email: this.getDocumentInstance.current_email,
                        expected_lease_duration: `${this.getApplication.expected_lease_duration}`,
                        quoted_rent_amount: this.formatQuotedRentAmount(this.getApplication.quoted_rent_amount),
                        contact_id: this.getApplication.contact_id ?? this.currentProfile.contactId,
                        lease_id: this.currentProfile.occupancyId,
                        middle_name: this.getDocumentInstance.applicant_middle_name ?? '',
                        home_phone: this.getDocumentInstance.applicant_home_phone,
                        birth_date: this.getDocumentInstance.applicant_birth_date,
                        marital_status: this.mapMaritalStatus(this.getDocumentInstance.applicant_marital_status),
                        application_process_status: process_status,
                        event_description,
                        event_name,
                        document_url,
                        coapplicants: this.mapCoApplicants(allPersons),
                        occupants: this.mapOccupants(currentApplicant),
                        emergency_contact: this.mapEmergencyContact(currentApplicant),
                        pet: this.mapPets(currentApplicant),
                        vehicle: this.mapVehicles(currentApplicant),
                        submission_date: this.getDocumentInstance.updated_at,
                        current_address: this.getDocumentInstance.current_address ?? '',
                        current_city: this.getDocumentInstance.current_city ?? '',
                        current_st: this.getDocumentInstance.current_st ?? '',
                        current_zip: this.getDocumentInstance.current_zip ?? '',
                        is_primary: currentParty?.is_primary,
                        person_role: currentApplicant?.person_role,
                    },
                });
                this.setApplicationId(currentApplicant?.application[0]?.application.application_id ?? this.getApplicationId);
                this.$router.push({
                    name: 'application_service.application_payment',
                    params: { propertyId: this.property.id },
                });
            } catch {
                this.notifyError(UPDATE_APPLICATION_ERROR);
            }
        },

        mapEmergencyContact(currentApplicant) {
            const emergencyContact =
                currentApplicant?.emergency_contact?.length &&
                currentApplicant?.emergency_contact[0] &&
                this.getDocumentInstance?.emergency_contact_name
                    ? {
                        person_id: currentApplicant?.emergency_contact[0]?.person_id,
                        contact_id: currentApplicant?.emergency_contact[0]?.contact_id,
                        name: this.getDocumentInstance?.emergency_contact_name,
                        email: this.getDocumentInstance.emergency_contact_email,
                        cell_phone: this.getDocumentInstance.emergency_contact_cell_phone,
                        home_phone: this.getDocumentInstance.emergency_contact_home_phone,
                        relation: this.getDocumentInstance.emergency_contact_relation,
                    }
                    : this.getDocumentInstance?.emergency_contact_name
                        ? {
                            name: this.getDocumentInstance?.emergency_contact_name,
                            email: this.getDocumentInstance?.emergency_contact_email,
                            cell_phone: this.getDocumentInstance.emergency_contact_cell_phone,
                            home_phone: this.getDocumentInstance.emergency_contact_home_phone,
                            relation: this.getDocumentInstance.emergency_contact_relation,
                        }
                        : null;

            return emergencyContact;
        },

        mapOccupants(currentApplicant) {
            const currentApplicantOccupants = currentApplicant.occupants;
            const occupantsSavedOnBluemoom = this.getDocumentInstance?.occupants?.filter(occupant => occupant?.name !== null);
            occupantsSavedOnBluemoom?.forEach(item => {
                if (item && !item?.name?.includes(' ')) {
                    item.name = `${item.name} Empty`;
                }
            });
            const uniqNames = new Set();
            currentApplicantOccupants?.forEach(occupant => uniqNames.add(`${occupant?.first_name} ${occupant?.last_name}`));
            const filteredBluemoonOccupants = occupantsSavedOnBluemoom?.filter(occupant => !uniqNames.has(occupant?.name));
            const mappedCurrentOccupants = currentApplicantOccupants?.map(item => {
                return {
                    name: `${item?.first_name} ${item?.last_name}`,
                    relation: item?.relation,
                    birth_date: item?.birth_date,
                    ssn: item?.ssn,
                    person_id: item?.person_id,
                    contact_id: item?.contact_id,
                };
            });
            const mappedNewOccupants = filteredBluemoonOccupants?.map(item => {
                return {
                    name: item?.name,
                    relation: item?.relation,
                    birth_date: item?.birth_date,
                    ssn: item?.ssn,
                };
            });
            return [...mappedCurrentOccupants, ...(mappedNewOccupants || [])];
        },

        mapVehicles(currenApplicant) {
            const vehiclesDocInstance =
                this.getDocumentInstance?.vehicles && this.getDocumentInstance?.vehicles?.filter(vehicle => vehicle.make !== null)?.length
                    ? this.getDocumentInstance?.vehicles?.filter(vehicle => vehicle.make !== null)
                    : [];
            let vehicles = [];
            switch (true) {
            case vehiclesDocInstance?.length && !currenApplicant?.vehicles?.length:
                vehicles = vehiclesDocInstance.map(vehicle => {
                    return {
                        customer_id: this.getApplication.customer_id,
                        business_unit_id: this.getApplication.business_unit_id,
                        vehicle_make: vehicle.make,
                        vehicle_year: vehicle.year,
                        vehicle_model: vehicle.model,
                        vehicle_color: vehicle.color,
                        license_plate_region: vehicle.license_st,
                        license_plate: vehicle.license_no ?? '',
                        primary_driver_party_id: currenApplicant?.person_id,
                        vehicle_type: 'n/a',
                    };
                });
                break;

            case !vehiclesDocInstance?.length && currenApplicant?.vehicles?.length:
                vehicles = currenApplicant?.vehicles?.length
                    ? currenApplicant?.vehicles.map(vehicle => {
                        return {
                            ...vehicle,
                            primary_driver_party_id: currenApplicant?.person_id,
                        };
                    })
                    : [];
                break;

            default:
                if (vehiclesDocInstance?.length > currenApplicant?.vehicles?.length) {
                    vehicles = vehiclesDocInstance.map((vehicle, i) => {
                        if (currenApplicant?.vehicles[i]?.vehicle_make === vehicle.make) {
                            return {
                                vehicle_id: currenApplicant?.vehicles[i]?.vehicle_id,
                                customer_id: this.getApplication.customer_id,
                                business_unit_id: this.getApplication.business_unit_id,
                                vehicle_make: vehicle.make,
                                vehicle_year: vehicle.year,
                                vehicle_model: vehicle.model,
                                vehicle_color: vehicle.color,
                                primary_driver_party_id: currenApplicant?.vehicles[i]?.primary_driver_party_id,
                                license_plate_region: vehicle.license_st,
                                vehicle_license_plate_text: vehicle.license_no ?? '',
                                vehicle_type: 'n/a',
                            };
                        }

                        return {
                            customer_id: this.getApplication.customer_id,
                            business_unit_id: this.getApplication.business_unit_id,
                            vehicle_make: vehicle.make,
                            vehicle_year: vehicle.year,
                            vehicle_model: vehicle.model,
                            vehicle_color: vehicle.color,
                            primary_driver_party_id: currenApplicant?.person_id,
                            license_plate_region: vehicle.license_st,
                            vehicle_license_plate_text: vehicle.license_no ?? '',
                            vehicle_type: 'n/a',
                        };
                    });
                } else {
                    vehicles = currenApplicant?.vehicles?.map((vehicleObj, i) => {
                        if (vehiclesDocInstance[i]?.make === vehicleObj.vehicle_make) {
                            return {
                                vehicle_id: vehicleObj.vehicle_id,
                                customer_id: this.getApplication.customer_id,
                                business_unit_id: this.getApplication.business_unit_id,
                                vehicle_make: vehiclesDocInstance[i].make,
                                vehicle_year: vehiclesDocInstance[i].year,
                                vehicle_model: vehiclesDocInstance[i].model,
                                vehicle_color: vehiclesDocInstance[i].color,
                                primary_driver_party_id: vehicleObj.primary_driver_party_id,
                                license_plate_region: vehiclesDocInstance[i].license_st,
                                vehicle_license_plate_text: vehiclesDocInstance[i].license_no ?? '',
                                vehicle_type: 'n/a',
                            };
                        }
                        return {
                            vehicle_id: vehicleObj.vehicle_id,
                            customer_id: this.getApplication.customer_id,
                            business_unit_id: this.getApplication.business_unit_id,
                            vehicle_make: vehicleObj.vehicle_make,
                            vehicle_year: vehicleObj.vehicle_year,
                            vehicle_model: vehicleObj.vehicle_model,
                            vehicle_color: vehicleObj.vehicle_color,
                            primary_driver_party_id: currenApplicant?.person_id,
                            license_plate_region: vehicleObj.license_plate_region,
                            vehicle_license_plate_text: vehicleObj.vehicle_license_plate_text ?? '',
                        };
                    });
                }
                break;
            }

            return vehicles;
        },

        mapPets(currenApplicant) {
            const petsDocInstance =
                this.getDocumentInstance?.pets && this.getDocumentInstance?.pets?.filter(pet => pet.name !== null)?.length
                    ? this.getDocumentInstance?.pets?.filter(pet => pet.name !== null)
                    : [];

            let pets = [];
            switch (true) {
            case petsDocInstance?.length && !currenApplicant?.pets?.length:
                pets = petsDocInstance.map(pet => {
                    return {
                        customer_id: this.getApplication.customer_id,
                        business_unit_id: this.getApplication.business_unit_id,
                        pet_weight: pet.weight,
                        pet_breed: pet.breed,
                        pet_owner_party_id: currenApplicant?.person_id,
                        pet_species: pet.name,
                        pet_sex: null,
                        age: pet.age,
                    };
                });
                break;

            case !petsDocInstance?.length && currenApplicant?.pets?.length:
                pets = currenApplicant?.pets?.length ? currenApplicant?.pets : [];
                break;

            default:
                if (petsDocInstance?.length > currenApplicant?.pets?.length) {
                    pets = petsDocInstance.map((pet, i) => {
                        if (currenApplicant?.pets[i]?.pet_species === pet.name) {
                            return {
                                pet_id: currenApplicant?.pets[i]?.pet_id,
                                customer_id: this.getApplication.customer_id,
                                business_unit_id: this.getApplication.business_unit_id,
                                pet_weight: pet.weight,
                                pet_breed: pet.breed,
                                pet_owner_party_id: currenApplicant?.pets[i]?.pet_owner_party_id,
                                pet_species: pet.name,
                                pet_sex: currenApplicant?.pets[i]?.pet_sex,
                                age: pet.age,
                            };
                        }

                        return {
                            customer_id: this.getApplication.customer_id,
                            business_unit_id: this.getApplication.business_unit_id,
                            pet_weight: pet.weight,
                            pet_breed: pet.breed,
                            pet_owner_party_id: currenApplicant?.person_id,
                            pet_species: pet.name,
                            pet_sex: null,
                            age: pet.age,
                        };
                    });
                } else {
                    pets = currenApplicant?.pets?.map((petObj, i) => {
                        if (petsDocInstance[i]?.name === petObj.pet_species) {
                            return {
                                pet_id: petObj.pet_id,
                                customer_id: this.getApplication.customer_id,
                                business_unit_id: this.getApplication.business_unit_id,
                                pet_weight: petsDocInstance[i].weight,
                                pet_breed: petsDocInstance[i].breed,
                                pet_owner_party_id: petObj.pet_owner_party_id,
                                pet_species: petObj.pet_species,
                                pet_sex: petObj.pet_sex,
                                age: petObj.age,
                            };
                        }
                        return {
                            pet_id: petObj.pet_id,
                            customer_id: this.getApplication.customer_id,
                            business_unit_id: this.getApplication.business_unit_id,
                            pet_weight: petObj.pet_weight,
                            pet_breed: petObj.pet_breed,
                            pet_owner_party_id: currenApplicant?.person_id,
                            pet_species: petObj.pet_species,
                            pet_sex: petObj.pet_sex,
                            age: petObj.age,
                        };
                    });
                }
                break;
            }
            return pets;
        },

        mapCoApplicants(persons) {
            let co_applicants =
                persons
                    ?.filter(
                        person => person.person_role.toLowerCase() === 'applicant' && person.contact_id !== this.currentProfile.contactId
                    )
                    ?.map(applicant => {
                        return {
                            person_id: applicant.person_id,
                            name: `${applicant.first_name} ${applicant.last_name}`,
                            email: applicant.email,
                        };
                    }) ?? [];

            const coApplicantsDocInstance = this.getDocumentInstance?.co_applicants?.filter(applicant => applicant.name !== null) ?? [];

            switch (true) {
            case coApplicantsDocInstance?.length && !co_applicants?.length:
                co_applicants = coApplicantsDocInstance;
                break;
            default:
                if (coApplicantsDocInstance?.length > co_applicants?.length) {
                    co_applicants = coApplicantsDocInstance.map((applicant, i) => {
                        if (co_applicants[i]?.email === applicant.email) {
                            return {
                                person_id: co_applicants[i].person_id,
                                name: co_applicants[i].name,
                                email: co_applicants[i].email,
                            };
                        }

                        return {
                            name: coApplicantsDocInstance[i].name,
                            email: coApplicantsDocInstance[i].email,
                        };
                    });
                }
                break;
            }
            return co_applicants;
        },

        mapMaritalStatus(status) {
            const marital_status = {
                SINGLE: 'Single',
                MARRIED: 'Married',
            }[status];
            return marital_status;
        },
    },
};
</script>
